@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./assets/font/Lato.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: Lato !important;
}

.red{
  color: red;
}

.footerBtn{
  background-color: transparent;
  color: #007bff;
  border: none;
  padding: 0;
}

.footerBtn:hover{
  color: #0056b3;
  text-decoration: underline;
}