.carousel-item > img { 
  width: 100%; 
}

.carousel_caption{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( 40%, -50% );
  width: 400px;
  font-size: 30px;
  font-weight: bold;
  color: white;
  box-sizing: border-box;
}

.signupBtn{
  text-transform: uppercase;
  background-color: yellowgreen ;
  color: white !important;
  border-radius: 8em !important;
  padding: 5px 25px !important;
  margin: 5px 0px;
}

.demoBtn{
  text-transform: uppercase;
  background-color: white !important;
  color: yellowgreen;
  border: 1px solid white !important;
  border-radius: 8em !important;
  padding: 5px 25px !important;
  margin: 0px 10px;
}

.counter{
  font-size: 4em;
  color: #e1c141;
}

@media screen and (max-width: 1199px) {
  .carousel_caption {
    font-size: 20px;
    transform: translate( 10%, -50% );
    width: 350px;
  }
  .signupBtn{
    padding: 5px 10px !important;
  }
  .demoBtn{
    padding: 5px 10px !important;
  }
}

@media screen and (max-width: 930px) {
  .carousel_caption {
    font-size: 18px;
    transform: translate( 5%, -50% );
    width: 350px;
  }
  .signupBtn{
    padding: 5px 10px !important;
  }
  .demoBtn{
    padding: 5px 10px !important;
  }
}

@media screen and (max-width: 749px) {
  .carousel_caption {
    font-size: 14px;
    transform: translate( 5%, -50% );
    width: 300px;
  }
  .signupBtn{
    padding: 5px 5px !important;
    font-size: 14px !important;
  }
  .demoBtn{
    padding: 5px 5px !important;
    font-size: 14px !important;
  }
}

@media screen and (max-width: 670px) {
  .carousel_caption {
    font-size: 12px;
    transform: translate( 5%, -50% );
    width: 250px;
  }
  .signupBtn{
    padding: 5px 5px !important;
    font-size: 10px !important;
  }
  .demoBtn{
    padding: 5px 5px !important;
    font-size: 10px !important;
  }
}

@media screen and (max-width: 599px) {
  .carousel_caption {
    font-size: 10px;
    transform: translate( 5%, -50% );
    width: 200px;
  }
  .signupBtn{
    padding: 5px 3px !important;
    font-size: 10px !important;
  }
  .demoBtn{
    padding: 5px 3px !important;
    font-size: 10px !important;
  }
}

@media screen and (max-width: 499px) {
  .carousel_caption {
    font-size: 8px;
    transform: translate( 5%, -50% );
    width: 175px;
  }
  .signupBtn{
    padding: 5px 2px !important;
    font-size: 8px !important;
  }
  .demoBtn{
    padding: 5px 2px !important;
    font-size: 8x !important;
  }
}