.mt-10 {
    margin-top: 10%;
}
.mt-10 h6{
    line-height: 28px;
    font-weight: normal;
}
.mt-10 li{
    line-height: 28px;
    margin-left: 5%;
}
.fieldsset {
    margin-top: 4%;
    font-family: Lato;
}
.fieldsset h6{
    margin-top: 1%;
    margin-left: 7%;
    line-height: 28px;
    font-size: 16px;
    font-style: normal;
}
.crmtitle {
    font-size: 22px;
    font-family: Lato;
    margin-left: 5%;
    color:#EAAB21 !important;
}
.crmpricing {
    background-color:#EAAB21 !important;
    border-color: #EAAB21 !important;
    border-radius: 17px 17px 17px 17px !important;
}
.crmgetstarted {
    background-color: #EAAB21 !important;
    border-color: #EAAB21 !important;
    color:#fff!important;
    border-radius: 17px 17px 17px 17px !important;
}
.crmbookademo {
    background-color: #fff !important;
    border-color: #EAAB21 !important;
    color:#EAAB21!important;
    border-radius: 17px 17px 17px 17px !important;
}

/* .fa { transform: scale(3,3); color:#EAAB21 !important;} */
    .crm .fa-dashcube,.crm .fa-trello,.crm .fa-envelope,.crm .fa-random,.crm .fa-bandcamp,.crm .fa-weibo,.crm .fa-envelope,.crm .fa-archive,
    .crm .fa-id-badge,.crm .fa-bell-o,.crm .fa-qrcode,.crm .fa-volume-control-phone,.crm .fa-building-o,.crm .fa-frown-o,.crm .fa-cubes
    .crm .fa-comment-o,.crm .fa-universal-access,.crm .fa-mobile,.crm .fa-indent,.crm .fa-eur,.crm .fa-contao,.crm .fa-calendar-o,.crm .fa-tasks,
    .crm .fa-flag,.crm .fa-book,.crm .fa-user-md,.crm .fa-database,.crm .fa-map-marker,.crm .fa-pencil-square-o,.crm .fa-building-o,
    .crm .fa-mobile,.crm .fa-cubes, .crm .fa-comment-o{
        transform: scale(3,3); color:#EAAB21 !important
}

@media screen and (max-width: 992px) {
    .crm .fa-dashcube,.crm .fa-trello,.crm .fa-envelope,.crm .fa-random,.crm .fa-bandcamp,.crm .fa-weibo,.crm .fa-envelope,.crm .fa-archive,
    .crm .fa-id-badge,.crm .fa-bell-o,.crm .fa-qrcode,.crm .fa-volume-control-phone,.crm .fa-building-o,.crm .fa-frown-o,.crm .fa-cubes
    .crm .fa-comment-o,.crm .fa-universal-access,.crm .fa-mobile,.crm .fa-indent,.crm .fa-eur,.crm .fa-contao,.crm .fa-calendar-o,.crm .fa-tasks,
    .crm .fa-flag,.crm .fa-book,.crm .fa-user-md,.crm .fa-database,.crm .fa-map-marker,.crm .fa-pencil-square-o,.crm .fa-building-o,
    .crm .fa-mobile,.crm .fa-cubes, .crm .fa-comment-o{
        transform: scale(2,2); color:#EAAB21 !important
    }

    .crmtitle {
      font-size: 18px;
    }
}

@media screen and (max-width: 700px) {
    .crm .fa-dashcube,.crm .fa-trello,.crm .fa-envelope,.crm .fa-random,.crm .fa-bandcamp,.crm .fa-weibo,.crm .fa-envelope,.crm .fa-archive,
    .crm .fa-id-badge,.crm .fa-bell-o,.crm .fa-qrcode,.crm .fa-volume-control-phone,.crm .fa-building-o,.crm .fa-frown-o,.crm .fa-cubes
    .crm .fa-comment-o,.crm .fa-universal-access,.crm .fa-mobile,.crm .fa-indent,.crm .fa-eur,.crm .fa-contao,.crm .fa-calendar-o,.crm .fa-tasks,
    .crm .fa-flag,.crm .fa-book,.crm .fa-user-md,.crm .fa-database,.crm .fa-map-marker,.crm .fa-pencil-square-o,.crm .fa-building-o,
    .crm .fa-mobile,.crm .fa-cubes, .crm .fa-comment-o{
      transform: scale(1.5,1.5); color:#EAAB21 !important
  }
  .crmtitle {
    font-size: 16px;
  }
}


@media screen and (max-width: 599px) {
    .crm .fa-dashcube,.crm .fa-trello,.crm .fa-envelope,.crm .fa-random,.crm .fa-bandcamp,.crm .fa-weibo,.crm .fa-envelope,.crm .fa-archive,
    .crm .fa-id-badge,.crm .fa-bell-o,.crm .fa-qrcode,.crm .fa-volume-control-phone,.crm .fa-building-o,.crm .fa-frown-o,.crm .fa-cubes
    .crm .fa-comment-o,.crm .fa-universal-access,.crm .fa-mobile,.crm .fa-indent,.crm .fa-eur,.crm .fa-contao,.crm .fa-calendar-o,.crm .fa-tasks,
    .crm .fa-flag,.crm .fa-book,.crm .fa-user-md,.crm .fa-database,.crm .fa-map-marker,.crm .fa-pencil-square-o,.crm .fa-building-o,
    .crm .fa-mobile,.crm .fa-cubes, .crm .fa-comment-o{
      transform: scale(1.2,1.2); color:#EAAB21 !important
  }
  .crmtitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 510px) {
    .crm .fa-dashcube,.crm .fa-trello,.crm .fa-envelope,.crm .fa-random,.crm .fa-bandcamp,.crm .fa-weibo,.crm .fa-envelope,.crm .fa-archive,
    .crm .fa-id-badge,.crm .fa-bell-o,.crm .fa-qrcode,.crm .fa-volume-control-phone,.crm .fa-building-o,.crm .fa-frown-o,.crm .fa-cubes
    .crm .fa-comment-o,.crm .fa-universal-access,.crm .fa-mobile,.crm .fa-indent,.crm .fa-eur,.crm .fa-contao,.crm .fa-calendar-o,.crm .fa-tasks,
    .crm .fa-flag,.crm .fa-book,.crm .fa-user-md,.crm .fa-database,.crm .fa-map-marker,.crm .fa-pencil-square-o,.crm .fa-building-o,
    .crm .fa-mobile,.crm .fa-cubes, .crm .fa-comment-o{
      transform: scale(1.1,1.1); color:#EAAB21 !important
  }
  .crmtitle {
    font-size: 12px;
  }
}
