.mt-5 h3{
    color: #636568 !important;
}
.mt-10 {
    margin-top: 10%;
}
.mt-10 h6{
    line-height: 28px;
    font-weight: normal;
}
.mt-10 li{
    line-height: 28px;
    margin-left: 5%;
}
.fieldsset {
    margin-top: 4%;
    font-family: Lato;
}
.fieldsset h6{
    margin-top: 1%;
    margin-left: 7%;
    line-height: 28px;
    font-size: 16px;
    font-style: normal;
}
.assettitle {
    font-size: 22px;
    font-family: Lato;
    margin-left: 5%;
    color:#92C952;
}
.pricing {
    background-color:#92C952 !important;
    border-color: #92C952 !important;
    border-radius: 17px !important;
    padding: 5px 25px !important;
    margin: 0 5px;
}
.bookademo {
    background-color: #fff !important;
    border-color: #92C952 !important;
    color:#92C952 !important;
    border-radius: 17px!important;
    margin: 0 5px;
}

.alignCenter{
  text-align: center;
}

.btnAlignCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .fa { transform: scale(3,3); color:#92C952} */

.icon .fa-dashcube, .icon .fa-trello, .icon .fa-random, .icon .fa-bandcamp,.icon .fa-weibo,.icon .fa-archive,.icon .fa-id-badge,
.icon .fa-bell-o,.icon .fa-qrcode,.icon .fa-building-o,.icon .fa-mobile,.icon .fa-cubes,.icon .fa-comment-o{
        transform: scale(3,3); color:#92C952 !important;
}

@media screen and (max-width: 992px) {
  .icon .fa-dashcube, .icon .fa-trello, .icon .fa-random, .icon .fa-bandcamp,.icon .fa-weibo,.icon .fa-archive,.icon .fa-id-badge,
  .icon .fa-bell-o,.icon .fa-qrcode,.icon .fa-building-o,.icon .fa-mobile,.icon .fa-cubes,.icon .fa-comment-o{
        transform: scale(2,2); color:#92C952 !important
    }

    .assettitle {
      font-size: 18px;
    }
}

@media screen and (max-width: 700px) {
  .icon .fa-dashcube, .icon .fa-trello, .icon .fa-random, .icon .fa-bandcamp,.icon .fa-weibo,.icon .fa-archive,.icon .fa-id-badge,
.icon .fa-bell-o,.icon .fa-qrcode,.icon .fa-building-o,.icon .fa-mobile,.icon .fa-cubes,.icon .fa-comment-o{
      transform: scale(1.5,1.5); color:#92C952 !important
  }
  .assettitle {
    font-size: 16px;
  }
}


@media screen and (max-width: 599px) {
  .icon .fa-dashcube, .icon .fa-trello, .icon .fa-random, .icon .fa-bandcamp,.icon .fa-weibo,.icon .fa-archive,.icon .fa-id-badge,
.icon .fa-bell-o,.icon .fa-qrcode,.icon .fa-building-o,.icon .fa-mobile,.icon .fa-cubes,.icon .fa-comment-o{
      transform: scale(1.2,1.2); color:#92C952 !important
  }
  .assettitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 510px) {
  .icon .fa-dashcube, .icon .fa-trello, .icon .fa-random, .icon .fa-bandcamp,.icon .fa-weibo,.icon .fa-archive,.icon .fa-id-badge,
.icon .fa-bell-o,.icon .fa-qrcode,.icon .fa-building-o,.icon .fa-mobile,.icon .fa-cubes,.icon .fa-comment-o{
      transform: scale(1.1,1.1); color:#92C952 !important
  }
  .assettitle {
    font-size: 12px;
  }
}

