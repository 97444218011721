.submitbtn {
  background-color: #92c952 !important;
  border-color: #92c952 !important;
  margin-left: 40px;
}

.btnlayer {
  text-align: center;
}
.texterror{
  color: red !important;
}
.jvectormap-container {
  height: 100%;
  width: 100%;
}

.time {
  width: 50px;
  border: 1px dotted blue;
  padding: 3px;
  margin: 5px;
  cursor: pointer;
}

.timeSelected {
  background-color: blue;
  color: white;
}

.timeContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.thanks{
  color: blue;
  font-size: 22px;
}

.dateBook{
  font-weight: bold;
  font-size: 24px;
}

.homeBtn{
  background-color: #4141da !important;
  border-color:  #4141da !important;
  padding: 5px;
}

.formDisable{
  pointer-events: none;
  opacity: 0.5;
}

.DateInput{
  display: none !important;
}

.SingleDatePicker_picker {
  /* z-index: 1; */
  background-color: #fff;
  position: initial !important;
}

@media screen and (max-width: 991px) {
  .marginForMd{
    margin-top: 10px !important;
  }
}