.navbarlink{
  color: black !important;
  font-size: 14px !important;
  display: block;
  padding: .5rem 1rem;
  text-decoration: none;
  background-color: transparent;
  font-family: Lato;
}

.dpmenu {
  font-family: Lato;
}
.menualign {
  margin-top: -4%;
  margin-left: auto;
}
.freetrial{
  /* border: 2px solid black; */
  border-radius: 18px;
  /* padding: 0.25rem !important; */
  color: #ffff !important;
  font-size: 14px !important;
  font-family: Lato;
  display: block;
  text-decoration: none;
  background-color: #e35e65;
  cursor: pointer;
}

.cardBg{
  background-color: white !important;
  padding: 3em !important;
}
.logo {
  width:15%;
  margin-left: 10%;
  cursor: pointer;
}
.staticmenu {
  color: black;
  font-family: Lato;
  font-size: 14px !important;
  text-decoration: none;
  cursor: pointer;
}
.freetrialimage img{
  width:100%;
  cursor: pointer;
}

@media screen and (max-width: 799px){
  .logo {
    width:15%;
    margin-left: 5%;
  }
}


@media screen and (max-width: 730px){
  .logo {
    width:13%;
    margin-left: 3%;
  }
  .menualign{
    margin-top: 0%;
  }
}

@media screen and (max-width: 599px){
  .navbarlink{
    font-size: 12px !important;
    padding: .3rem 1rem;
  } 
  .staticmenu {
    font-size: 12px !important;
  }
  .freetrial{
    padding: 0.25rem!important;
    font-size: 12px !important;
  }
  .menualign{
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 540px){
  .navbarlink{
    font-size: 10px !important;
    padding: .2rem 1rem;
  } 
  .staticmenu {
    font-size: 10px !important;
  }
  .freetrial{
    padding: 0.25rem!important;
    font-size: 10px !important;
  }
}
