.partnerimg img {
    width:30%;
}
.fa-list,.fa-connectdevelop,.fa-whatsapp,.fa-user-circle,.fa-wpforms,.fa-street-view{
    transform: scale(3,3);color:#6ec1e4 !important;
}
.partner {
    color:#20419a !important;
}

.arrowicon{
    font-size: x-large;
   
    margin-top: 71px;
}
.subtitle {
    color:black !important;
    font-weight: unset !important;
}
.partner_caption{
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate( 40%, -50% );
    width: 400px;
    font-size: 30px;
    font-weight: bold;
    color: #B37474;
    box-sizing: border-box;
  }
  .arrowgreater img {
    width:70%;
    margin-top: 55px;
  }