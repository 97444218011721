.mt-10 {
    margin-top: 10%;
}
.mt-10 h6{
    line-height: 28px;
    font-weight: normal;
}
.mt-10 li{
    line-height: 28px;
    margin-left: 5%;
}
.fieldsset {
    margin-top: 4%;
    font-family: Lato;
}
.fieldsset h6{
    margin-top: 1%;
    margin-left: 7%;
    line-height: 28px;
    font-size: 16px;
    font-style: normal;
}
.fieldsettitle {
    font-size: 22px;
    font-family: Lato;
    margin-left: 5%;
    color:#02AB9E !important;
}
.payroll_caption{
    position: absolute;
    top: 8%;
    left: 50%;
    transform: translate( 40%, -50% );
    width: 400px;
    font-size: 30px;
    font-weight: bold;
    color: white;
    box-sizing: border-box;
  }
.payrollpricing {
    background-color:#02AB9E !important;
    border-color: #02AB9E !important;
    border-radius: 17px 17px 17px 17px !important;
}
.payrollbookademo {
    background-color: #fff !important;
    border-color: #02AB9E !important;
    color:#02AB9E !important;
    border-radius: 17px 17px 17px 17px !important;
}
.payrollgetstarted {
    background-color: #02AB9E !important;
    border-color: #02AB9E !important;
    color:#fff !important;
    border-radius: 17px 17px 17px 17px !important;
}
/* .fa { transform: scale(3,3); color:#02AB9E !important;} */
.fa-dashcube,.fa-trello,.fa-random,.fa-universal-access,.fa-bandcamp,.fa-weibo,.fa-hdd-o,
.fa-archive,.fa-id-badge,.fa-bell-o,.fa-qrcode,.fa-floppy-o,.fa-industry,.fa-eur,.fa-rub,
.fa-pencil-square-o,.fa-mobile,.fa-building-o,.fa-cubes,.fa-comment-o{
    transform: scale(3,3); color:#02AB9E !important
}
@media screen and (max-width: 992px) {
    .fa-dashcube,.fa-trello,.fa-random,.fa-universal-access,.fa-bandcamp,.fa-weibo,.fa-hdd-o,
.fa-archive,.fa-id-badge,.fa-bell-o,.fa-qrcode,.fa-floppy-o,.fa-industry,.fa-eur,.fa-rub,
.fa-pencil-square-o,.fa-mobile,.fa-building-o,.fa-cubes,.fa-comment-o{
        transform: scale(2,2); color:#02AB9E !important
    }

    .fieldsettitle {
      font-size: 18px;
    }
}

@media screen and (max-width: 700px) {
    .fa-dashcube,.fa-trello,.fa-random,.fa-universal-access,.fa-bandcamp,.fa-weibo,.fa-hdd-o,
.fa-archive,.fa-id-badge,.fa-bell-o,.fa-qrcode,.fa-floppy-o,.fa-industry,.fa-eur,.fa-rub,
.fa-pencil-square-o,.fa-mobile,.fa-building-o,.fa-cubes,.fa-comment-o{
      transform: scale(1.5,1.5); color:#02AB9E !important
  }
  .fieldsettitle {
    font-size: 16px;
  }
}


@media screen and (max-width: 599px) {
    .fa-dashcube,.fa-trello,.fa-random,.fa-universal-access,.fa-bandcamp,.fa-weibo,.fa-hdd-o,
.fa-archive,.fa-id-badge,.fa-bell-o,.fa-qrcode,.fa-floppy-o,.fa-industry,.fa-eur,.fa-rub,
.fa-pencil-square-o,.fa-mobile,.fa-building-o,.fa-cubes,.fa-comment-o{
      transform: scale(1.2,1.2); color:#02AB9E !important
  }
  .fieldsettitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 510px) {
    .fa-dashcube,.fa-trello,.fa-random,.fa-universal-access,.fa-bandcamp,.fa-weibo,.fa-hdd-o,
.fa-archive,.fa-id-badge,.fa-bell-o,.fa-qrcode,.fa-floppy-o,.fa-industry,.fa-eur,.fa-rub,
.fa-pencil-square-o,.fa-mobile,.fa-building-o,.fa-cubes,.fa-comment-o{
      transform: scale(1.1,1.1); color:#02AB9E !important
  }
  .fieldsettitle {
    font-size: 12px;
  }
}