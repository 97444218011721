.hederimg{
    text-align: center;
}

p.outset {
    border-style: outset;
    color: darkgray;
    border-width: thin;

}

.locastyle{
    color: #3FA8E4;
    font-size: large;
    font-weight: 600;
}

.sendbtn{
    color: white !important;
    background-color: #3FA8E4  !important;

}
.contact_caption {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate( 40%, -50% );
    width: 400px;
    font-size: 30px;
    font-weight: bold;
    color: white;
    box-sizing: border-box;
}
