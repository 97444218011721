.headertext{
    text-align: center;
    color: #20419a;
    font-size: 24px;
}
.sendbtn{
    color: white !important;
    background-color: #3FA8E4  !important;

}
.title h3{
    color:#20419a !important;
}
.support_caption{
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate( 40%, -50% );
    width: 400px;
    font-size: 30px;
    font-weight: bold;
    color: white;
    box-sizing: border-box;
  }