.alignCenter{
  text-align: center;
}

.header{
  color: #20419a;
  font-weight: bold;
  font-size: 36px;
  margin-top: 20px;
}

.subheader{
  font-weight: bold;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 25px;
  color:#636568;
}
.learnmore {
  text-transform: capitalize !important;
  border-radius: 8em !important;
}

.appCard{
  padding: 10px !important;
  width: 275px !important;
  height: 380px;
  margin: 5px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.appCardText{
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  height: 150px;
}
.whyfouraai {
  background-color: #05A8D1 !important;
  color:#fff;
}
.wevalue{
  color:#fff !important;
}
.fouraaiap h2{
  color: #20419a;
}
.fouraaiapps img {
  width:30%;
}
.fouraaiapps h5{
  color:black !important;
}
.takelook {
  background-color: #e35e65 !important;
  color: #ffff;
}
.takelook h3{
  color:#ffff !important;
}
.explorebtn {
  color:#ffff;
  background-color: #E1C141 !important;
  border-radius: 20px 20px 20px 20px !important;
  align-items: center !important;
  border-color: #E1C141 !important;
}
.ourproduct {
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px;
  height: 250px;
}
.ourproduct img{
  width: 200px !important;
  height: 100px;
  text-align: center;
  line-height: 83%;
  margin: auto;
  position: relative;
  top: 50%;
  transform: translate(10%, -50%);
}
.assetsbtn {
  background-color: #92C952 !important;
  border-radius: 20px 20px 20px 20px !important;
  border-color: #92C952 !important;
}
.crmbtn {
  background-color: #EAAB21 !important;
  border-radius: 20px 20px 20px 20px !important;
  border-color: #EAAB21 !important;
}
.incidentsbtn{
  background-color: #25A9E0 !important;
  border-radius: 20px 20px 20px 20px !important;
  border-color: #25A9E0 !important;
}
.payrollbtn {
  background-color: #02AB9E !important;
  border-radius: 20px 20px 20px 20px !important;
  border-color: #02AB9E !important;
}