.blogCard{
  padding: 25px 10px !important;
  width: 100% !important;
  margin: 1em 0;
  border: 1px solid black !important;
  border-radius: 25px !important;
}

.appCardText{
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  height: 150px;
}

.blogheader{
  font-size: 20px;
  font-weight: 600;
}

.fa-angle-right{
  font-size: 8px !important;
  color: lightgrey !important;
  float: right;
  margin-right: 10px;
  line-height: 25px !important;
}

.listItem{
  margin: 10px 0px;
  border-bottom: 1px solid lightgray;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
}


.pointer{
  cursor: pointer;
}

.blogSubheader{
  color: lightgrey;
  font-size: 16px;
}

.fa-user{
  font-size: 8px !important;
  color: lightgray !important;
  margin-right: 15px;
}
.fa-calendar{
  font-size: 8px !important;
  color: lightgray !important;
  margin-right: 15px;
  margin-left: 25px;
}

.fa-comments-o{
  font-size: 8px !important;
  color: lightgray !important;
  margin-right: 15px;
  margin-left: 25px;
}

.hrLine{
  margin: 25px 0;
  border: 1px solid lightgray;
}

.red{
  color: #ED1C24
}

.cmtBtn{
  background-color: white !important;
  color: black !important;
  padding: 5px 20px !important;
}

.replyHdr{
  display: flex;
  flex-direction: row;
  height: 45px;
  background-color: lightgray;
  padding: 10px 5px;
}

.comsection{
  border: 1px solid lightgray;
  margin-bottom: 15px;
}

.user{
  color: blue;
  font-weight: bold;
  margin-right: 10px;
}

.date{
  margin-left: 10px;
  color: gray;
  font-style: italic;
}

.reply{
  font-weight: bold;
  color: blue;
  margin-left: 70%;
  cursor: pointer;
}

.comment{
  padding: 15px;
}