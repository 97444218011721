.mt-10 {
    margin-top: 10%;
}
.mt-5 h3{
    color:#2E58C6;
}
.alignCenter h3{
    color:#20419a !important;
}
.story {
    text-align: center;
    line-height: 28px !important;
}
.story h4{
    line-height: 37px;
    font-family: Lato;
}
/* .fa { transform: scale(3,3); color:#92C952; }*/

.vertical-timeline-element--work p{
    color:#ffff;
}
.iconchange {
    margin-top: 30%; margin-left: 40%;
}
.titletag h3{
    text-align: center;
}

.dpmenu a{
  text-decoration: none !important;
  color: black !important;
  background-color: white !important;
}
.whoicon .fa-dashcube {
    color:#ffff !important;
}