.mt-10 {
    margin-top: 10%;
}
.mt-10 h6{
    line-height: 28px;
    font-weight: normal;
}
.mt-10 li{
    line-height: 28px;
    margin-left: 5%;
}
.fieldsset {
    margin-top: 4%;
    font-family: Lato;
}
.fieldsset h6{
    margin-top: 1%;
    margin-left: 7%;
    line-height: 28px;
    font-size: 16px;
    font-style: normal;
}
.incidenttitle {
    font-size: 22px;
    font-family: Lato;
    margin-left: 5%;
    color:#25A9E0 !important;
}
.incidentpricing {
    background-color:#25A9E0 !important;
    border-color: #25A9E0 !important;
    border-radius: 17px 17px 17px 17px !important;
}
.incidentbookademo {
    background-color: #fff !important;
    border-color: #25A9E0 !important;
    color:#25A9E0 !important;
    border-radius: 17px 17px 17px 17px !important;
}
.incidentgetstarted {
    background-color: #25A9E0 !important;
    border-color: #25A9E0 !important;
    color:#fff !important;
    border-radius: 17px 17px 17px 17px !important;
}
.incident_caption{
    position: absolute;
    top: 8%;
    left: 50%;
    transform: translate( 40%, -50% );
    width: 400px;
    font-size: 30px;
    font-weight: bold;
    color: white;
    box-sizing: border-box;
  }
/* .fa { transform: scale(3,3); color:#25A9E0 !important;} */
.incid .fa-dashcube,.incid .fa-trello,.incid .fa-random,.incid .fa-bandcamp,.incid .fa-weibo,.incid .fa-archive, .incid .fa-id-badge,
.incid .fa-bell-o,.incid .fa-qrcode,.incid .fa-building-o,.incid .fa-map-marker,.incid .fa-clock-o,.incid .fa-calendar-o,.incid .fa-mobile,
.incid .fa-pencil-square-o,.incid .fa-building-o,.incid .fa-mobile,.incid .fa-user-md,.incid .fa-cubes{
    transform: scale(3,3); color:#25A9E0 !important
}
@media screen and (max-width: 992px) {
    .incid .fa-dashcube,.incid .fa-trello,.incid .fa-random,.incid .fa-bandcamp,.incid .fa-weibo,.incid .fa-archive, .incid .fa-id-badge,
.incid .fa-bell-o,.incid .fa-qrcode,.incid .fa-building-o,.incid .fa-map-marker,.incid .fa-clock-o,.incid .fa-calendar-o,.incid .fa-mobile,
.incid .fa-pencil-square-o,.incid .fa-building-o,.incid .fa-mobile,.incid .fa-user-md,.incid .fa-cubes{
        transform: scale(2,2); color:#25A9E0 !important
    }

    .incidenttitle {
      font-size: 18px;
    }
}

@media screen and (max-width: 700px) {
    .incid .fa-dashcube,.incid .fa-trello,.incid .fa-random,.incid .fa-bandcamp,.incid .fa-weibo,.incid .fa-archive, .incid .fa-id-badge,
.incid .fa-bell-o,.incid .fa-qrcode,.incid .fa-building-o,.incid .fa-map-marker,.incid .fa-clock-o,.incid .fa-calendar-o,.incid .fa-mobile,
.incid .fa-pencil-square-o,.incid .fa-building-o,.incid .fa-mobile,.incid .fa-user-md,.incid .fa-cubes{
      transform: scale(1.5,1.5); color:#25A9E0 !important
  }
  .incidenttitle {
    font-size: 16px;
  }
}


@media screen and (max-width: 599px) {
    .incid .fa-dashcube,.incid .fa-trello,.incid .fa-random,.incid .fa-bandcamp,.incid .fa-weibo,.incid .fa-archive, .incid .fa-id-badge,
.incid .fa-bell-o,.incid .fa-qrcode,.incid .fa-building-o,.incid .fa-map-marker,.incid .fa-clock-o,.incid .fa-calendar-o,.incid .fa-mobile,
.incid .fa-pencil-square-o,.incid .fa-building-o,.incid .fa-mobile,.incid .fa-user-md,.incid .fa-cubes{
      transform: scale(1.2,1.2); color:#25A9E0 !important
  }
  .incidenttitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 510px) {
    .incid .fa-dashcube,.incid .fa-trello,.incid .fa-random,.incid .fa-bandcamp,.incid .fa-weibo,.incid .fa-archive, .incid .fa-id-badge,
.incid .fa-bell-o,.incid .fa-qrcode,.incid .fa-building-o,.incid .fa-map-marker,.incid .fa-clock-o,.incid .fa-calendar-o,.incid .fa-mobile,
.incid .fa-pencil-square-o,.incid .fa-building-o,.incid .fa-mobile,.incid .fa-user-md,.incid .fa-cubes{
      transform: scale(1.1,1.1); color:#25A9E0 !important
  }
  .incidenttitle {
    font-size: 12px;
  }
}